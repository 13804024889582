import React from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import { Button } from "react-bootstrap";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignInAlt,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { faUserCog as faUserReg } from "@fortawesome/pro-regular-svg-icons";
import HeaderMainLogo from "./HeaderMainLogo";
import UNDPPALogo from "../assets/images/undppa-logo.png";

const SidebarAccount = (props) => {
  const { t } = useTranslation();

  let items = [];

  const { pathname } = useLocation();

  if (!props.account_is_logged_in) {
    items = items.concat([
      {
        path: "/account/login",
        label: t("Log In"),
        icon: faSignInAlt,
      },
      {
        path: "/account/register",
        label: t("Register"),
        icon: faUserReg,
      },
    ]);
  } else {
    items = items.concat([
      {
        path: "/account/",
        label: t("Profile"),
        icon: null,
      },
      {
        button: true,
        path: "/account/logout",
        label: t("Log Out"),
        icon: null,
        enabled: false,
      },
    ]);
  }

  function renderItemIcon(icon) {
    if (icon) {
      return (
        <>
          <FontAwesomeIcon icon={icon} />
          &nbsp;&nbsp;
        </>
      );
    }
  }

  function renderNavItem(item) {
    let output;
    if (item.enabled === false) {
      return;
    }
    if (item.button) {
      output = (
        <>
          <li className="nav-item border-bottom" key={item.path}>
            <Button variant="primary" href={item.path} block>
              {renderItemIcon(item.icon)}
              {item.label}
            </Button>
          </li>
        </>
      );
    } else {
      output = (
        <li className="nav-item border-bottom" key={item.path}>
          <Link
            className={
              "nav-link pl-3 py-3 " + (pathname === item.path ? "active" : "")
            }
            to={item.path}
          >
            {renderItemIcon(item.icon)}
            {item.label}
          </Link>
        </li>
      );
    }
    return output;
  }

  const logoutButton = () => {
    if (props.account_is_logged_in) {
      return (
        <div className="p-3">
          <Button variant="primary" href="/account/logout" block>
            <Trans>Log Out</Trans>
          </Button>
        </div>
      );
    }
  };

  return (
    <aside className="sidebar z1 bg-body-light">
      <HeaderMainLogo logo={<img src={UNDPPALogo} alt="UNDPPA Logo" />} />
      <div className="sidebar-inner-container">
        <h4 className="sidebar-header">
          <Trans>Account</Trans>
        </h4>
        <div className="pt-0 px-0 bg-body-light">
          <ul className="nav flex-column bg-light">
            {items.map((item) => renderNavItem(item))}
            {props.children}
          </ul>
          {logoutButton()}
        </div>
      </div>
      <footer className="sidebar-footer px-3 py-2">
        <Link to="/help">
          <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
          <Trans i18nKey="helpLink">Documentation and Help</Trans>
        </Link>
      </footer>
    </aside>
  );
};

export default SidebarAccount;
